window.appRuntimeEnv = {}

async function loadDefaultConfig() {
    try {
        window.appRuntimeEnv = {
            ...window.appRuntimeEnv,
            ...process.env,
        }
    } catch {
        //
    }
}

async function loadConfig() {
    try {
        const config = await fetch('/config.json')
        const configJson = await config.json()
        window.appRuntimeEnv = {
            ...window.appRuntimeEnv,
            ...configJson,
        }
    } catch {
        //
    }
}

const loadAppVersionsConfig = async () => {
    try {
        const appVersions = await fetch('/app_versions.json')
        const appVersionsJson = await appVersions.json()
        window.appRuntimeEnv = {
            ...window.appRuntimeEnv,
            ...appVersionsJson,
        }
    } catch {
        //
    }
}

const retrieveApiConfig = async () => {
    try {
        const runtimeEnvSettings = JSON.parse(
            JSON.stringify(window.appRuntimeEnv)
        )
        const apiUrl = runtimeEnvSettings.APP_API_URL ?? process.env.APP_API_URL
        if (typeof apiUrl !== 'string') {
            return
        }

        const apiConfig = await fetch(apiUrl)
        const apiConfigJson = await apiConfig.json()
        const apiConfigObj = { APP_VERSIONAPI: apiConfigJson.version }
        window.appRuntimeEnv = {
            ...window.appRuntimeEnv,
            ...apiConfigObj,
        }
    } catch {
        //
    }
}

loadDefaultConfig()
    .then(() => loadConfig())
    .then(() => loadAppVersionsConfig())
    .then(() => retrieveApiConfig())
    .then(() => {
        return import('./app.js')
    })
